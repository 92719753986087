.topbar .container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.topbar .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    color: grey;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
}

.topbar .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.topbar .buttons .button {
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 10px;
    cursor: pointer;
    transition: 0.3s;
    background-color: var(--color)
}

.topbar .buttons button:disabled {
    color: rgb(65, 65, 65);
}
