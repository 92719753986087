.map-container {
    grid-row: 2;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid grey;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.location-header {
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid grey;
    background-color: var(--secondary-color);
}

.map.google-button {
    grid-column: 1;
    justify-self: center;
}

.map.title {
    grid-column: 2;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
    background-color: inherit;
}

.map.hide-button {
    grid-column: 3;
    justify-self: center;
}
