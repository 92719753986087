.alert-cards {
  height: 100%;
  width: 100%;
  background-color: inherit;
  color: var(--font-color);
}

.alert-card {
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 98%;
  /* border-bottom: 1px solid var(--accent-color); */
  border-radius: 5px;
  border: 1px solid grey;
  background-color: var(--secondary-color);
  margin: 5px;
}

.primary-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.secondary-info {
  display: flex;
  flex-direction: column;
}

.alert-type {
  grid-row: 1;
  grid-column: 1;
  font-size: 1.2rem;
  font-weight: bold;
}

.alert-name {
  grid-row: 2;
  grid-column: 1;
  margin-bottom: 10px;
}

.alert-address {
  grid-row: 3;
  grid-column: 1;
  font-size: 0.8rem;
}

.alert-time {
  margin-top: auto;
  grid-row: 3;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  text-align: right;
  align-content: flex-end;
  justify-content: flex-end;
}

.alert-status {
  grid-row: 1;
  grid-column: 2;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
}

@keyframes flash {
  0% { color: var(--danger-color); }
  50% { color: var(--font-color); }
  100% { color: var(--danger-color); }
}

.alert-card.active {
  animation: flash 0.8s linear infinite;
}

.alert-card.selected {
  filter: invert(1);
  border: 1px solid white;
}
