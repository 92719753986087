.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--color);
    color: white;
}

.error-code {
    font-size: 6em;
    margin: 0;
}

.error-message {
    font-size: 2em;
}