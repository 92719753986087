.alert-page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    max-height: 100vh;
    background-color: var(--color);
    color: 	#C0C0C0;
    /* overflow: hidden; */
}

header {
    grid-row: 1;
    display: grid;
    grid-template-columns: auto auto auto min-content;
    align-items: center;
    justify-items: center;
    width: 100%;
    background-color: inherit;
    border-bottom: 1px solid grey;
}

.dashboard-container {
    grid-row: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    color: white;
    overflow: hidden;
    transition: flex-basis 0.3s ease-out;
}

.dashboard {
    flex: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: white;
    overflow: hidden;
    transition: flex-basis 0.3s ease-out;
    padding: 10px;
}

@keyframes background-flash {
    0% { background-color: var(--danger-color); }
    50% { background-color: rgb(202, 43, 43); }
    100% { background-color: var(--danger-color); }
  }
  
.alert-page-container.active {
    animation: background-flash 0.8s linear infinite;
    color: white;
}

/* .dashboard.map-hidden {
    flex: 0 0 86%;
} */

.topbar {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 98%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid grey;
    background-color: var(--secondary-color);
}

main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: inherit;
    overflow: auto;

    /* For Webkit browsers (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) rgba(255, 255, 255, 0.5);
}

.location-container {
    flex: 3;
    display: grid;
    grid-template-rows: min-content auto;
    align-items: center;
    height: 100%;
    background-color: inherit;
    transition: transform 0.5s ease-in-out;
    transition: flex 0.3s ease-out;
    border-radius: 5px;
    padding: 10px;
}