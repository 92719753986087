header .logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 5%;
}

header .station {
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

header .status-container {
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
}

header .status-container .status {
    font-weight: bold;
    margin: 0;
    margin-right: 10px;
}

header .status-container .status.online {
    color: rgba(57, 255, 57, 0.842);
}

header .status-container .status.offline {
    color: rgba(255, 43, 43, 0.877);
}

header .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

header .subtitle {
    font-size: 0.8rem;
    margin: 0;
}

header .status-container .last-login {
    margin: 0;
    margin-left: 10px;
}

header .time-container {
    grid-column: 3;
    white-space: nowrap;
    font-size: 0.75rem;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

header .logout-container {
    padding: 10px;
}