*, *::before, *::after {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

#root {
  height: 100vh;
}

:root {
  --color: #282c34;
  --secondary-color: #192d33;
  --accent-color: #61dafb;

  --highlight-color: #444b58; /* Blue */
  --success-color: #2ecc71; /* Green */
  --danger-color: #e84e3d; /* Red */
  --font-color: #e0e0e0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}