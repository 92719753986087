/* CSS */

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color:var(--color);
  }
  
  .login-container {
    width: 350px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  }
  
  .title-container {
    text-align: left;
    margin-bottom: 60px;
  }

  .title-container > p {
    font-size: 14px;
    color: #5d5d5d;
    margin: 0; 
    padding: 0;
  }
  
  .login-title {
    color: #333;
    font-size: 24px;
    font-weight: 900;
    text-align: left;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .input-group .forgot-password {
    text-align: right;
    font-size: 12px;
    color: #007BFF;
    cursor: pointer;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .input-group .msg {
    color: red;
    font-size: 12px;
  }
  
  .login-button {
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }

  